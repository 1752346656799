/// Title
$navigation-tile-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$navigation-tile-title-font--large: $navigation-tile-title-font;
$navigation-tile-title-text-transform: lowercase;
$navigation-tile-title-margin: rem-calc(15 0 0);
$navigation-tile-title-margin--large: rem-calc(15 0 0);

/// Description
$navigation-tile-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$navigation-tile-description-font--large: $navigation-tile-description-font;
$navigation-tile-description-color: color(text-secondary);
$navigation-tile-description-text-transform: lowercase;

@import "@lora/05-components/navigation-tile";