$selection-group-info-title-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$selection-group-info-title-font--large: normal #{rem-calc(14)} / #{rem-calc(20)} $font-primary-light;
$selection-group-info-title-color: color(primary);
$selection-group-info-title-color--large: color(text-secondary);
$selection-group-info-title-text-transform: lowercase;
$selection-group-info-subtitle-font: 300 #{rem-calc(10)} / 1.4 $font-primary;
$selection-group-info-subtitle-font--large: 300 #{rem-calc(12)} / 1.4 $font-primary;
$selection-group-item-active-background: color(light);
$selection-group-item-active-before-border: 2px solid color(dark);
$selection-group-item-focus-before-border: 3px solid color(dark);
$selection-group-item-hover-border-color--large: color(dark);
$selection-group-info-subtitle-text-transform: lowercase;
$selection-group-item-hover-border-color: color(dark);
$selection-group-item-border: 1px solid color(element-background);
$selection-group-item-active-title-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;

// Selection Group item image
$selection-group-item-image-width: rem-calc(60);
$selection-group-item-image-margin: 0 auto rem-calc(15);

// Radio selection
$selection-group-radio-padding: rem-calc(21 20 17 48);
$selection-group-radio-field-margin: rem-calc(0 0 10 -26);

// Selection Group item info
$selection-group-item-info-font: #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;

@import "@lora/05-components/selection-group";