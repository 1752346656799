$content-list-title-font: normal #{rem-calc(18)} / 1.3 $font-secondary;
$content-list-title-font--large: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$content-list-title-text-transform: lowercase;
$content-list-title-text-align--large: left;

$content-list-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-list-description-font--large: $content-list-description-font;
$content-list-description-text-color: color(text-secondary);
$content-list-description-text-transform: lowercase;
$content-list-description-text-transform--large: $content-list-description-text-transform;
$content-list-description-text-align--large: left;

$content-list-article-border-bottom: none;
$content-list-article-border-bottom--large: $content-list-article-border-bottom;
$content-list-article-first-padding--large: 0;
$content-list-article-border-top: none;
$content-list-article-padding--large: 0;

@import "@lora/05-components/content-list";