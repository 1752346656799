// Site Map Title
$content-sitemap-title-font: 200 #{rem-calc(18)} / #{rem-calc(24)} $font-primary;
$content-sitemap-title-font-size--large: #{rem-calc(18)};
$content-sitemap-title-text-transform: lowercase;
$content-sitemap-title-padding--large: 0 0 rem-calc(5);
$content-sitemap-title-margin: 0 0 rem-calc(15);
$content-sitemap-title-margin--large: $content-sitemap-title-margin;

// Site Map Subtitle
$content-sitemap-subtitle-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$content-sitemap-subtitle-font-size--large: rem-calc(14);
$content-sitemap-subtitle-text-transform: lowercase;

// Site Map Head Link
$content-sitemap-head-link-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-sitemap-head-link-color: color(text-secondary);
$content-sitemap-head-link-text-transform: lowercase;

// Site Map Items
$content-sitemap-items-margin: 0 0 rem-calc(32);

@import "@lora/05-components/content/content-sitemap";