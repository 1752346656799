$search-breadcrumbs-separator: none;
$search-breadcrumbs-height: rem-calc(65);
$search-panel-button-background--medium-down: color(primary);
$search-phrases-height: rem-calc(55);
$search-phrases-height--large: rem-calc(34);
$search-phrases-margin: rem-calc(25 0 20);
$search-product-results-count-font: #{rem-calc(18)} / 1.3 $font-secondary;
$search-product-results-count-font--large: #{rem-calc(26)} / 1.15 $font-secondary;
$search-product-results-count-text-transform--large: lowercase;
$search-product-results-count-text-width: 68%;
$search-product-results-count-margin--medium-down: rem-calc(15 0);
$search-product-results-count-text-transform: lowercase;
$search-product-results-separator--large: none;
$search-tools-margin--large: 0;
$search-no-results-phrases-height: rem-calc(65);
$search-product-results-padding--large: rem-calc(40 0 0);
$search-results-count-inline-margin: 0 rem-calc(30) 0 0;
$search-results-count-inline-color: color(text-secondary);

@import "@lora/04-layout/search";